'use client';
import {
	Box,
	Button,
	Chip,
	Drawer,
	Grid,
	SpeedDial,
	SpeedDialAction,
	Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { useClientTranslation, useSessionClient } from '@negotium/common';
import { useContextCartShop } from '../ContextCartShop';
import { getTotalProductsOfCartShop } from '@negotium/libCustomer';
import { useNotification } from '../ErrorContext';
import { useRouter } from 'next/navigation';
import { ProductsOfCart } from '../ProductsOfCart';
import { useContextGeneral } from '../ContextGeneral';
import { Close } from '@mui/icons-material';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import { ModalRequestWholesaler } from '../ModalRequestWholesaler';
import LayersIcon from '@mui/icons-material/Layers';
type CartShopProps = {
	lng: 'es' | 'en';
};

export const CartShop = ({ lng }: CartShopProps) => {
	const { t } = useClientTranslation(lng, ['catalog', 'common']);
	const [openCartShop, setOpenCartShop] = useState<boolean>(false);
	const [open, setOpen] = useState<boolean>(false);
	const { setErrorNotification } = useNotification();
	const user = useSessionClient();
	const { setShowLogin } = useContextGeneral();
	const [showModalRequestWholesaler, setShowModalRequestWholesaler] =
		useState<boolean>(false);

	const router = useRouter();

	const { countProductCartShop, cartShopId, setCountProductCartShop } =
		useContextCartShop();

	useEffect(() => {
		if (cartShopId) {
			getTotalProductsOfCartShop(cartShopId)
				.then((response) => {
					setCountProductCartShop(response.data);
				})
				.catch(() => {
					setErrorNotification(t('errorToGetTotalProductOfCartShop'));
				});
		}
	}, [cartShopId]);

	function handlerConfirm() {
		if (!user) {
			setShowLogin(true);
		} else {
			setCountProductCartShop(0);
			setOpenCartShop(false);
			router.push('/checkout');
		}
	}

	function handlerShowWholesalerRequest() {
		if (!user) {
			setShowLogin(true);
		} else {
			setShowModalRequestWholesaler(true);
		}
	}

	const actions: Array<{
		icon: JSX.Element;
		type: string;
		name: string;
		handlerClick: () => void;
	}> = [
		{
			icon: (
				<ShoppingCartIcon
					sx={{
						color: (theme) => theme.palette.white?.main || 'white',
					}}
				/>
			),
			type: 'cartShop',
			name: t('seeCartShop'),
			handlerClick: () => setOpenCartShop(!openCartShop),
		},
		{
			icon: (
				<WarehouseIcon
					sx={{
						color: (theme) => theme.palette.white?.main || 'white',
					}}
				/>
			),
			type: 'wholesalerRequest',
			name: t('wholesaler', { ns: 'common' }),
			handlerClick: () => handlerShowWholesalerRequest(),
		},
	];

	const list = () => (
		<Box
			sx={{
				width: { md: 500, xs: '100%' },
				p: { md: '25px', xs: '10px' },
			}}
			role='presentation'
		>
			<Grid
				container
				sx={{
					mt: 2,
				}}
				spacing={1}
			>
				<Grid item xs={10}>
					<Typography
						variant='TitleSection'
						fontSize='1.4rem'
						fontWeight='bold'
					>
						{t('cartShop')}
					</Typography>
				</Grid>
				<Grid item xs={2} display='flex' justifyContent='flex-end'>
					<Button
						color='secondary'
						variant='text'
						sx={{
							borderRadius: '50%',
							p: 0,
							minWidth: '35px',
							height: '35px',
						}}
						onClick={() => setOpenCartShop(false)}
					>
						<Close />
					</Button>
				</Grid>
				<Grid item xs={12} mt={2}>
					{openCartShop && (
						<ProductsOfCart
							lng={lng}
							onConfirm={() => handlerConfirm()}
							onCloseModalCart={() => setOpenCartShop(false)}
						/>
					)}
				</Grid>
			</Grid>
		</Box>
	);

	return (
		<Box width='100%'>
			<Box
				sx={{
					'& > :not(style)': { m: 1 },
					position: 'fixed',
					right: { md: '3%', xs: '10%' },
					bottom: '25%',
					width: '50px',
					height: '50px',
					zIndex: 99,
				}}
			>
				<Box
					sx={{
						flexGrow: 1,
						position: 'relative',
					}}
				>
					{countProductCartShop > 0 && !open && (
						<Chip
							color='primary'
							label={countProductCartShop}
							sx={{
								position: 'absolute',
								bottom: '-13px',
								right: '-13px',
								fontSize: '14px',
								zIndex: 9999,
							}}
						/>
					)}
					<SpeedDial
						ariaLabel={t('directAccess')}
						icon={<LayersIcon color='secondary' />}
						color='secondary'
						onClose={() => setOpen(false)}
						onOpen={() => setOpen(true)}
						open={open}
						FabProps={{
							sx: {
								background: (theme) => theme.palette.white?.main || 'white',
								boxShadow: (theme) =>
									`0px 0px 5px ${theme.palette.secondary?.main}`,
							},
						}}
					>
						{actions.map((action) => (
							<SpeedDialAction
								key={action.type}
								tooltipTitle={
									<Box display='flex' alignItems='center'>
										{action.type === 'cartShop' &&
											countProductCartShop > 0 &&
											open && (
												<Chip
													color='primary'
													label={countProductCartShop}
													sx={{
														mr: 1,
													}}
												/>
											)}
										<Typography
											variant='ExplicationText'
											fontWeight='600'
											color='secondary'
											sx={{
												width: 'max-content',
											}}
										>
											{action.name}
										</Typography>
									</Box>
								}
								tooltipOpen
								color='secondary'
								icon={action.icon}
								onClick={action.handlerClick}
								TooltipClasses={{
									tooltipPlacementRight:
										action.type === 'wholesalerRequest' ? 'right' : 'top',
								}}
								FabProps={{
									size: 'medium',
									sx: {
										background: (theme) => theme.palette.warning.main,
									},
								}}
							/>
						))}
					</SpeedDial>
				</Box>
			</Box>
			<Drawer
				anchor={'right'}
				open={openCartShop}
				onClose={() => setOpenCartShop(false)}
				PaperProps={{
					sx: {
						background: (theme) => theme.palette.primary.light,
					},
				}}
				sx={{
					background: (theme) => theme.palette.secondary.light,
					backdropFilter: 'blur(5px)',
				}}
			>
				{list()}
			</Drawer>
			<ModalRequestWholesaler
				open={showModalRequestWholesaler}
				lng={lng}
				onClose={() => setShowModalRequestWholesaler(false)}
			/>
		</Box>
	);
};
